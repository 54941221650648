<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
    :style="{
      height: '100%'
    }"
  >
    <v-container class="align-center d-flex justify-center" :style="{ height: '100%' }">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card max-width="400" class="ma-auto">
            <v-card-title class="pt-15 justify-center">
              <h3 class="text-center">
                LOGIN SAYBLUE
              </h3>
            </v-card-title>
            <v-card-text>
              <v-text-field
                ref="loginId"
                v-model="auth.username"
                prepend-inner-icon="mdi-account"
                label="Username"
              />
              <v-text-field
                ref="loginPassword"
                v-model="auth.password"
                prepend-inner-icon="mdi-key"
                type="password"
                label=" Password"
                @keydown="enterLogin"
              />
              <v-snackbar
                v-model="isLoginError"
              >
                <span v-html="errorText" />
                <template #action="{ attrs }">
                  <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="isLoginError = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="d-flex">
                <v-dialog
                  v-model="isShowRegister"
                  persistent
                  width="600"
                  :fullscreen="mobile"
                  :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                >
                  <template #activator="{ attrs, on }">
                    <v-btn
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      회원가입
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="pt-10 pt-md-3">
                      <v-icon class="mr-2">
                        mdi-list-box-outline
                      </v-icon>
                      <h5>
                        Sign Up
                      </h5>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        ref="id"
                        v-model="signup.id"
                        label="아이디"
                        placeholder="아이디"
                        dense
                        filled
                        hide-details
                        outlined
                        class="mb-2"
                        :append-icon="signup.isDuplicate ? 'mdi-cancel' : 'mdi-check-bold'"
                        @keyup="duplCheck(signup.id)"
                      />
                      <v-text-field
                        ref="email"
                        v-model="signup.email"
                        label="이메일"
                        placeholder="이메일"
                        type="email"
                        dense
                        hide-details
                        filled
                        outlined
                        class="mb-2"
                      />
                      <v-text-field
                        ref="password"
                        v-model="signup.password"
                        label="비밀번호"
                        placeholder="비밀번호"
                        type="password"
                        dense
                        filled
                        hide-details
                        outlined
                        class="mb-2"
                      />
                      <v-text-field
                        ref="passwordConfirm"
                        v-model="signup.passwordConfirm"
                        label="비밀번호 확인"
                        placeholder="비밀번호 확인"
                        type="password"
                        dense
                        filled
                        hide-details
                        outlined
                        class="mb-2"
                      />
                      <v-text-field
                        ref="username"
                        v-model="signup.username"
                        label="이름"
                        placeholder="이름"
                        dense
                        filled
                        hide-details
                        outlined
                        class="mb-2"
                      />
                      <v-text-field
                        ref="userAddress"
                        v-model="signup.userAddress"
                        label="주소"
                        placeholder="주소"
                        dense
                        hide-details
                        filled
                        outlined
                        class="mb-2"
                        @focusin="addressSearch(null)"
                      />
                      <v-text-field
                        ref="userAddressDetail"
                        v-model="signup.userAddressDetail"
                        label="상세주소"
                        placeholder="상세주소"
                        dense
                        hide-details
                        filled
                        outlined
                        class="mb-2"
                      />
                      <v-text-field
                        ref="mobile"
                        v-model="signup.mobile"
                        label="전화번호"
                        placeholder="전화번호"
                        dense
                        filled
                        hide-details
                        outlined
                        class="mb-2"
                      />
                      <v-text-field
                        ref="userAccount"
                        v-model="signup.userAccount"
                        label="계좌정보"
                        placeholder="은행명 / 계좌 / 이름"
                        dense
                        filled
                        outlined
                        hide-details
                        class="mb-2"
                      />
                      <v-text-field
                        ref="recommender"
                        v-model="signup.recommender"
                        label="추천인"
                        placeholder="추천인/소개받은 분 아이디가 있을 경우 넣어주세요."
                        hide-details
                        dense
                        filled
                        outlined
                        :append-icon="signup.isExistingRecommender ? 'mdi-check-bold' : 'mdi-cancel'"
                        @keyup="recommenderCheck"
                      />
                    </v-card-text>
                    <v-card-text v-if="aliases && aliases.length > 0">
                      <v-card
                        v-for="(alias, i) in aliases"
                        :key="'alias-' + alias.index + '-' + i"
                      >
                        <v-card-title>
                          추가사용자 {{ i + 1 }}
                          <v-btn
                            small
                            color="error"
                            class="ml-2"
                            @click="deleteAlias(i)"
                          >
                            삭제
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                :ref="'alias' + alias.index + 'Id'"
                                v-model="alias.id"
                                label="아이디"
                                placeholder="아이디"
                                :append-icon="alias.isDuplicate ? 'mdi-cancel' : 'mdi-check-bold'"
                                dense
                                filled
                                outlined
                                hide-details
                                class="mb-2"
                                @keyup="duplAliasCheck(alias)"
                              />
                              <v-text-field
                                :ref="'alias' + alias.index + 'Username'"
                                v-model="alias.username"
                                label="이름"
                                placeholder="이름"
                                dense
                                filled
                                outlined
                                hide-details
                                class="mb-2"
                              />
                              <v-text-field
                                :ref="'alias' + alias.index + 'Mobile'"
                                v-model="alias.mobile"
                                label="휴대폰번호"
                                placeholder="휴대폰번호"
                                dense
                                filled
                                outlined
                                hide-details
                                class="mb-2"
                              />
                              <v-text-field
                                :ref="'alias' + alias.index + 'UserAddress'"
                                v-model="alias.userAddress"
                                label="주소"
                                placeholder="주소"
                                dense
                                filled
                                outlined
                                hide-details
                                class="mb-2"
                                @focusin="addressSearch(alias)"
                              />
                              <v-text-field
                                :ref="'alias' + alias.index + 'UserAddressDetail'"
                                v-model="alias.userAddressDetail"
                                label="상세 주소"
                                placeholder="상세 주소"
                                dense
                                filled
                                outlined
                                hide-details
                                class="mb-2"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        width="100%"
                        text
                        @click="addAlias"
                      >
                        추가 사용자
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions class="d-flex justify-center flex-wrap">
                      <v-btn
                        width="100%"
                        class="mx-0 mb-2"
                        @click="signUp"
                      >
                        회원가입
                      </v-btn>
                      <v-btn
                        width="100%"
                        class="mx-0"
                        @click="closeRegister"
                      >
                        창 닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer />
                <v-dialog
                  v-model="isShowFinder"
                  width="500px"
                  max-width="100%"
                  persistent
                >
                  <template #activator="{ attrs, on }">
                    <v-btn
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      아이디/비밀번호 찾기
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <v-icon class="mr-2">
                        mdi-account-question-outline
                      </v-icon>
                      <h5 class="mb-0">
                        아이디 찾기
                      </h5>
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <v-text-field
                        v-model="finder.id.username"
                        label="이름"
                        dense
                        filled
                        outlined
                        hide-details
                        class="mb-2"
                      />
                      <v-text-field
                        v-model="finder.id.mobile"
                        label="전화번호"
                        dense
                        filled
                        outlined
                      />
                    </v-card-text>
                    <v-card-actions class="d-flex flex-wrap px-5">
                      <v-btn
                        width="100%"
                        class="mx-0 mb-2"
                        :loading="isLoading"
                        @click="findId"
                      >
                        인증메일 발송
                      </v-btn>
                    </v-card-actions>
                    <v-card-title>
                      <v-icon class="mr-2">
                        mdi-dialpad
                      </v-icon>
                      <h5 class="mb-0">
                        비밀번호 찾기
                      </h5>
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <v-text-field
                        v-model="finder.password.id"
                        label="아이디"
                        dense
                        filled
                        outlined
                        hide-details
                        class="mb-2"
                      />
                      <v-text-field
                        v-model="finder.password.username"
                        label="이름"
                        dense
                        filled
                        outlined
                        hide-details
                        class="mb-2"
                      />
                      <v-text-field
                        v-model="finder.password.mobile"
                        label="전화번호"
                        dense
                        filled
                        outlined
                      />
                    </v-card-text>
                    <v-card-actions class="d-flex flex-wrap px-5">
                      <v-btn
                        width="100%"
                        class="mx-0 mb-2"
                        :loading="isLoading"
                        @click="findPassword"
                      >
                        인증메일 발송
                      </v-btn>
                      <v-btn
                        width="100%"
                        class="mx-0"
                        :loading="isLoading"
                        @click="closeFinder"
                      >
                        창닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <v-btn
                width="100%"
                class="mt-5"
                large
                @click="login"
              >
                Login
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <DaumAddress
        ref="address"
        :show-address="isShowAddress"
        :style="{ zIndex: 1000 }"
        @close="isShowAddress = false"
        @update="updateAddress"
      />
    </v-container>
  </v-lazy>
</template>

<script>
  import DaumAddress from '../../components/core/DaumAddress'
  export default {
    name: 'Login',
    components: {
      DaumAddress,
    },
    data () {
      return {
        isAdmin: this.$route.query.isAdmin || false,
        auth: {
          username: this.$route.query.username || '',
          password: this.$route.query.password || '',
        },
        edit: {
          username: true,
          password: true,
        },
        isLoading: false,
        isShowRegister: false,
        isShowFinder: false,
        isLoginError: false,
        errorText: null,
        finder: {
          id: {
            username: null,
            mobile: null,
          },
          password: {
            username: null,
            mobile: null,
            id: null,
          },
        },
        signup: {
          id: null,
          email: null,
          password: null,
          passwordConfirm: null,
          username: null,
          userAddress: null,
          userAddressDetail: null,
          mobile: null,
          userAccount: null,
          recommender: null,
          isDuplicate: true,
        },
        isShowAddress: false,
        aliases: [],
        aliasMaxIndex: 0,
        selectedAlias: null,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      if (this.isAdmin && this.auth.username && this.auth.password) {
        this.$nextTick()
        this.login()
      }
    },
    methods: {
      findId () {
        if (!this.finder.id.username) {
          alert('등록된 이름를 입력해주세요.')
          return
        }
        if (!this.finder.id.mobile) {
          alert('등록된 전화번호를 입력해주세요.')
          return
        } else {
          const mobileCheck = /^[0-9]*$/
          const mobile = this.finder.id.mobile
          const isValidMobile = mobileCheck.test(mobile)
          if (!isValidMobile) {
            this.snackAlert('전화번호는 숫자만 입력해주세요.')
            return false
          }
        }
        this.isLoading = true
        const url = '/find/id'
        this.$axios.post(url, this.finder.id).then(res => {
          this.isLoading = false
          this.snackAlert('회원님의 이메일로 등록된 정보를 발송하였습니다.')
          this.closeFinder()
        })
      },
      findPassword () {
        if (!this.finder.password.id) {
          alert('등록된 아이디를 입력해주세요.')
          return
        }
        if (!this.finder.password.username) {
          alert('등록된 이름를 입력해주세요.')
          return
        }
        if (!this.finder.password.mobile) {
          alert('등록된 전화번호를 입력해주세요.')
          return
        } else {
          const mobileCheck = /^[0-9]*$/
          const mobile = this.finder.password.mobile
          const isValidMobile = mobileCheck.test(mobile)
          if (!isValidMobile) {
            this.snackAlert('전화번호는 숫자만 입력해주세요.')
            return false
          }
        }
        this.isLoading = true
        const url = '/find/password'
        this.$axios.post(url, this.finder.password).then(res => {
          this.isLoading = false
          this.snackAlert('회원님의 이메일로 등록된 정보를 발송하였습니다.')
          this.closeFinder()
        })
      },
      deleteAlias (index) {
        this.aliases.splice(index, 1)
      },
      addressSearch (alias) {
        this.$refs.address.addressSearch()
        this.selectedAlias = alias
        this.isShowAddress = true
      },
      updateAddress (address) {
        if (this.selectedAlias) {
          this.selectedAlias.userAddress = address
          this.selectedAlias = null
        } else {
          this.signup.userAddress = address
        }
      },
      async signUp () {
        if (this.validateUserInfo() && this.validateAliasInfo()) {
          const signUpUrl = '/signup'
          this.signup.aliases = this.aliases
          await this.$axios.post(signUpUrl, this.signup).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.snackAlert('회원가입을 완료하였습니다.\r\n관리자 승인 후 사용이 가능합니다.')
              this.closeRegister()
            }
          }).catch(() => {
            this.snackAlert('회원가입 중 오류가 발생하였습니다.')
          })
        }
      },
      async recommenderCheck () {
        if (this.signup.recommender && this.signup.recommender.length >= 2) {
          const url = '/check/recommender'
          await this.$axios.get(url, { params: { id: this.signup.recommender } }).then(res => {
            if (res.data.status === 'SUCCESS') {
              this.signup.isExistingRecommender = true
            } else {
              this.signup.isExistingRecommender = false
            }
          }).catch(() => {
            this.signup.isExistingRecommender = false
          })
        }
      },
      async duplCheck (id) {
        if (id.length >= 2) {
          const url = '/check/duplicate'
          await this.$axios.get(url, { params: { id: id } }).then(res => {
            if (res.data.status === 'ERROR') {
              this.signup.isDuplicate = true
            } else {
              this.signup.isDuplicate = false
            }
          }).catch(() => {
            this.signup.isDuplicate = true
          })
        }
      },
      async duplAliasCheck (alias) {
        if (alias.id && alias.id.length >= 2) {
          const url = '/check/duplicate'
          await this.$axios.get(url, { params: { id: alias.id } }).then(res => {
            if (res.data.status === 'ERROR') {
              alias.isDuplicate = true
            } else {
              alias.isDuplicate = false
            }
          }).catch(() => {
            alias.isDuplicate = true
          })
        }
      },
      // eslint-disable-next-line max-statements
      validateAliasInfo () {
        for (const alias of this.aliases) {
          const id = alias.id
          const username = alias.username
          const mobile = alias.mobile
          const userAddress = alias.userAddress
          const userAddressDetail = alias.userAddressDetail
          const refId = `alias${alias.index}Id`
          const refUsername = `alias${alias.index}Username`
          const refUserAddressDetail = `alias${alias.index}UserAddressDetail`
          const refUserMobile = `alias${alias.index}Mobile`
          if (!id || id.length < 3 || id.length > 16) {
            this.snackAlert('사용자 아이디는 3자 이상 16자 이하로<br> 입력해주세요.')
            this.$refs[refId][0].focus()
            return false
          } else {
            const engCheck = /^[a-z0-9]/g
            if (!engCheck.test(this.signup.id)) {
              this.snackAlert('사용자 아이디는 영문 및 숫자로만<br> 구성되어야 합니다.')
              this.$refs[refId][0].focus()
              return false
            }
          }
          if (alias.isDuplicate) {
            this.snackAlert(id + '는 이미 사용중인 아이디입니다.')
            this.$refs[refId][0].focus()
            return false
          }
          if (!username) {
            this.snackAlert('회원님의 이름을 입력해주세요.')
            this.$refs[refUsername][0].focus()
            return false
          } else if (username.length < 2 || username.length > 16) {
            this.snackAlert('회원님의 이름은 2자 이상 16자 이하로<br> 입력해주세요.')
            this.$refs[refUsername][0].focus()
            return false
          }
          const mobilePattern = /^[0-9]+$/
          if (!mobilePattern.test(mobile)) {
            this.snackAlert('휴대폰 번호는 숫자만 입력해주세요.')
            this.$refs[refUserMobile][0].focus()
            return
          }
          if (!userAddress) {
            this.snackAlert('주소를 입력해주세요.')
            return false
          }
          if (!userAddressDetail) {
            this.snackAlert('상세주소를 입력해주세요.')
            this.$refs[refUserAddressDetail][0].focus()
            return false
          }
        }
        return true
      },
      // eslint-disable-next-line max-statements
      validateUserInfo () {
        if (!this.signup.id || this.signup.id.length < 3 || this.signup.id.length > 16) {
          this.snackAlert('사용자 아이디는 3자 이상 16자 이하로<br> 입력해주세요.')
          this.$refs.id.focus()
          return false
        } else {
          const engCheck = /^[a-z0-9]/g
          if (!engCheck.test(this.signup.id)) {
            this.snackAlert('사용자 아이디는 영문 및 숫자로만<br> 구성되어야 합니다.')
            return false
          }
        }
        if (this.signup.isDuplicate) {
          this.snackAlert(this.signup.id + '는 이미 사용중인 아이디입니다.')
          this.$refs.id.focus()
          return false
        }
        if (!this.signup.email) {
          this.snackAlert('이메일을 입력해주세요.')
          return false
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.signup.email)) {
          this.snackAlert('이메일 형식에 맞지 않습니다.')
          this.$refs.email.focus()
          return false
        }
        if (!this.signup.password) {
          this.snackAlert('패스워드를 입력해주세요.')
          this.$refs.password.focus()
          return false
        }
        if (!this.signup.passwordConfirm) {
          this.snackAlert('패스워드 확인란을 입력해주세요.')
          this.$refs.passwordConfirm.focus()
          return false
        }
        if (!/^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,20}$/.test(this.signup.password)) {
          this.snackAlert('패스워드는 영문, 숫자, 특수문자 중 2가지 이상<br>조합하여 8~20자로 입력해주세요.')
          return false
        }
        if (this.signup.password !== this.signup.passwordConfirm) {
          this.snackAlert('패스워드가 서로 다릅니다.')
          this.$refs.passwordConfirm.focus()
          return false
        }
        if (!this.signup.username) {
          this.snackAlert('회원님의 이름을 입력해주세요.')
          this.$refs.username.focus()
          return false
        } else if (this.signup.username.length < 2 || this.signup.username.length > 16) {
          this.snackAlert('회원님의 이름은 2자 이상 16자 이하로<br> 입력해주세요.')
          this.$refs.username.focus()
          return false
        }
        if (!this.signup.userAddress) {
          this.snackAlert('주소를 입력해주세요.')
          return false
        }
        if (!this.signup.userAddressDetail) {
          this.snackAlert('상세주소를 입력해주세요.')
          this.$refs.userAddressDetail.focus()
          return false
        }
        if (!this.signup.mobile) {
          this.snackAlert('전화번호를 입력해주세요.')
          this.$refs.mobile.focus()
          return false
        }
        const mobileCheck = /^[0-9]*$/
        const mobile = this.signup.mobile
        const isValidMobile = mobileCheck.test(mobile)
        if (!isValidMobile) {
          this.snackAlert('전화번호는 숫자만 입력해주세요.')
          return false
        }
        if (!this.signup.userAccount) {
          this.snackAlert('계좌정보를 입력해주세요.')
          this.$refs.userAccount.focus()
          return false
        }
        if (this.signup.recommender && !this.signup.isExistingRecommender) {
          this.snackAlert('추천인 아이디를 다시 확인해주세요.')
          this.$refs.recommender.focus()
          return false
        }
        return true
      },
      login () {
        if (!this.auth.username) {
          this.snackAlert('아이디를 입력해주세요.')
          this.$refs.loginId.focus()
          return false
        }
        if (!this.auth.password) {
          this.snackAlert('패스워드를 입력해주세요.')
          this.$refs.loginPassword.focus()
          return false
        }
        const data = {
          auth: {
            username: this.auth.username,
            password: this.auth.password,
          },
        }
        this.$axios.post('/login', data.auth).then(res => {
          if (res && res.data && res.data.authToken && res.data.user) {
            this.$store.dispatch('user/login', res.data)
          } else {
            this.snackAlert('일치하는 사용자가 존재하지 않거나, <br>가입 대기 중인 사용자입니다.')
          }
        }).catch(err => {
          console.log(err)
        })
      },
      enterLogin () {
        if (event.keyCode === 13) {
          this.login()
        }
      },
      addAlias () {
        this.aliases.push({
          index: this.aliasMaxIndex,
          id: null,
          username: null,
          userAddress: null,
          userAddressDetail: null,
          isDuplicate: true,
        })
        this.aliasMaxIndex = this.aliasMaxIndex + 1
      },
      snackAlert (text) {
        this.errorText = text
        this.isLoginError = true
      },
      closeRegister () {
        this.signup = {
          id: null,
          email: null,
          password: null,
          passwordConfirm: null,
          username: null,
          userAddress: null,
          userAddressDetail: null,
          mobile: null,
          userAccount: null,
          recommender: null,
          isDuplicate: true,
        }
        this.aliases = []
        this.isShowRegister = false
      },
      closeFinder () {
        this.finder = {
          id: {
            username: null,
            email: null,
          },
          password: {
            id: null,
            username: null,
            email: null,
          },
        }
        this.isShowFinder = false
      },
    },
  }
</script>

<style scoped>

</style>
