<template>
  <v-overlay
    :style="{
      display: isShowDaumPostCode ? 'flex' : 'none',
      zIndex: 1000,
    }"
    class="address-overlay"
  >
    <v-card light>
      <v-card-title class="justify-end" @click="closeDaumPostCode">
        <v-icon>
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text ref="embed" />
    </v-card>
  </v-overlay>
</template>

<script>
  export default {
    name: 'DaumAddress',
    props: [
      'showAddress',
    ],
    data () {
      return {
        address: '',
      }
    },
    computed: {
      isShowDaumPostCode () {
        return this.showAddress
      },
    },
    methods: {
      addressSearch () {
        const self = this
        // eslint-disable-next-line no-undef
        new daum.Postcode({
          oncomplete (data) {
            Promise.resolve(data).then(o => {
              const { address } = data
              return new Promise((resolve, reject) => {
                // eslint-disable-next-line no-undef
                const geocoder = new daum.maps.services.Geocoder()
                geocoder.addressSearch(address, (result, status) => {
                  // eslint-disable-next-line no-undef
                  if (status === daum.maps.services.Status.OK) {
                    const { x, y } = result[0]
                    resolve({ lat: y, lon: x })
                  } else {
                    reject(status)
                  }
                })
              })
            })
            let address = ''
            let extraAddress = ''
            if (data.userSelectedType === 'R') {
              address = data.roadAddress
              if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                extraAddress += data.bname
              }
              if (data.buildingName !== '' && data.apartment === 'Y') {
                extraAddress += (extraAddress !== '' ? ', ' + data.buildingName : data.buildingName)
              }
              if (extraAddress !== '') {
                extraAddress = ' (' + extraAddress + ')'
              }
            } else {
              address = data.jibunAddress
            }
            self.address = address + extraAddress
            self.update()
          },
          onClose () {
            self.closeDaumPostCode()
          },
          width: '100%',
        }).embed(this.$refs.embed)
      },
      closeDaumPostCode () {
        this.$emit('close')
      },
      update () {
        this.$emit('update', this.address)
      },
    },
  }
</script>

<style scoped lang="scss">
.address-overlay::v-deep {
  .v-overlay__content {
    width: 500px !important;
    max-width: 100% !important;
  }
}
</style>
